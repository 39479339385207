import React, { useEffect, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { Link } from "react-router-dom";
import config from "../../services/config";
//import "./pdfViewer.css";

//this is required to load the pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfViewer = ({ pdfUrl }) => {
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [style, setStyle] = useState({ display: "block" });
  const [currentPDF, setCurrentPDF] = useState(null);

  useEffect(() => {
    console.log("pdfUrl", pdfUrl);
    const currentUrl = `${config.PROTOCOL}${config.IMAGE_HOST}/` + pdfUrl;
    console.log("currentUrl", currentUrl);
    setCurrentPDF(currentUrl);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOutClass = isMinZoom ? "disabled" : "clickable";
  const zoomInClass = isMaxZoom ? "disabled" : "clickable";

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };
  return (
    <div>
      <div className="pdf-container">
        <div className="desktop">
          {" "}
          <div style={style} className="pdf-controls">
            <div class="d-flex flex-row bd-highlight mb-3">
              <div class="p-2 bd-highlight">
                {" "}
                <button
                  className="e-btn"
                  onClick={handlePrevPage}
                  disabled={pageNumber === 1}
                >
                  &lt;
                </button>
                <span
                  className="e-btn"
                  style={{ marginLeft: 1, marginRight: 1 }}
                >
                  {pageNumber} of {numPages}
                </span>
                <button
                  className="e-btn"
                  onClick={handleNextPage}
                  disabled={pageNumber === numPages}
                >
                  &gt;
                </button>
              </div>
              <div class="p-2 bd-highlight">
                <div>
                  <div className="e-btn" onClick={zoomOut}>
                    <i className={`fas fa-search-minus mx-3 ${zoomOutClass}`} />
                  </div>
                  <div
                    style={{ marginLeft: 1, marginRight: 1 }}
                    className="e-btn"
                  >
                    <span>{(scale * 100).toFixed()}%</span>
                  </div>
                  <div className="e-btn" onClick={zoomIn}>
                    <i className={`fas fa-search-plus mx-3 ${zoomInClass}`} />
                  </div>
                </div>
              </div>
              <div class="p-2 bd-highlight">
                <Link
                  style={{ marginLeft: 1, marginRight: 1 }}
                  className="e-btn"
                  to={`/pdf-premium-mentor-fullscreen-view`}
                >
                  FullScreen
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div
                style={{ flexDirection: "row" }}
                className="col-12 col-md-6 d-flex align-items-center justify-content-between m-3 p-3"
              >
                <div>
                  <button
                    style={{ background: "#2b4eff" }}
                    onClick={handlePrevPage}
                    disabled={pageNumber === 1}
                  >
                    <i
                      style={{
                        color: "white",
                        padding: 5,
                      }}
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                    ></i>
                  </button>
                  <span
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  >
                    {pageNumber} of {numPages}
                  </span>
                  <button
                    style={{ background: "#2b4eff" }}
                    onClick={handleNextPage}
                    disabled={pageNumber === numPages}
                  >
                    <i
                      style={{
                        color: "white",
                        padding: 5,
                      }}
                      className="fa fa-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
                <div
                  style={{ flexDirection: "row" }}
                  className="d-flex justify-content-between align-items-center"
                >
                  <i
                    style={{
                      color: "#2b4eff",
                      padding: 5,
                    }}
                    className={`fas fa-search-minus ${zoomOutClass}`}
                    onClick={zoomOut}
                  />
                  &nbsp;
                  <div>{(scale * 100).toFixed()}%</div>
                  &nbsp;
                  <i
                    style={{
                      color: "#2b4eff",
                      padding: 5,
                    }}
                    className={`fas fa-search-plus ${zoomInClass}`}
                    onClick={zoomIn}
                  />
                </div>

                <div>
                  <Link
                    style={{
                      marginLeft: 1,
                      marginRight: 1,
                    }}
                    className="e-btn"
                    to={`/pdf-premium-mentor-fullscreen-view`}
                  >
                    FullScreen
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Document
          file={currentPDF}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
        >
          <Page
            size="A4"
            style={{ backgroundColor: "tomato" }}
            pageNumber={pageNumber}
            fullScreen={true}
            width={"600"}
            scale={scale}
          />
        </Document>
      </div>
    </div>
  );
};

export default PdfViewer;
